import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/style/index.module.css"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../components/logo';

//const {GATSBY_API_URL} = process.env;

const IndexPage = () => (
	<Layout>
		<Container fluid className="g-0">
			<Row className="g-0 min-vh-100">
				<Col xs="12" lg="6">
					<div className={'min-vh-100 banner-size-page ' + styles.bgHome}></div>
				</Col>
				<Col xs="12" lg="6">
					<Row className="justify-content-center h-100 g-0">
						<Col xs="12" md="10" lg="10" xl="8">
							<div className="py-5 h-100 d-flex flex-column">
								<div className="text-center pt-3"><Logo/></div>
								<div className="d-flex flex-column h-100 justify-content-center">
									<div className="text-center">
										<h1>Your journey to optimized wellness starts here.</h1>
										<div className='my-5'>Tell us about yourself. Getting to know you will help us build your own, personalized formula for optimal wellness.</div>
										<a href="/quiz" className="btn btn-outline-primary rounded-5 px-4" >Begin Quiz</a>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Healthycell" />

export default IndexPage
